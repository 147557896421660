import React from 'react';
import { graphql } from 'gatsby';
import BlogLanding from '../components/organisms/BlogLanding/BlogLanding';

const Category = props => {
  const { data, pageContext } = props;
  const { title: siteTitle } = data.site.siteMetadata;
  const { name: category } = pageContext;
  const {
    allWpPost: { edges: posts },
    allWpCategory: { nodes: categories }
  } = data;

  return (
    <BlogLanding
      posts={posts || []}
      categories={categories || []}
      pageContext={pageContext}
      title={`${category} | ${siteTitle}`}
    />
  );
};

export default Category;

export const pageQuery = graphql`
  query CategoryPage($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    allWpCategory {
      nodes {
        id
        uri
        name
        description
      }
    }
  }
`;
